<template>
  <div class="ud-body ud-body-card">
    <a-row :gutter="16">
      <a-col :lg="6" :md="8" :sm="24" :xs="24">
        <a-card :bordered="false">
          <div class="ud-text-center">
            <div class="user-info-avatar-group" @click="showCropper = true">
              <a-avatar :size="110" :src="pageData.avatar" />
              <upload-outlined class="user-info-avatar-icon" />
            </div>
            <h1>{{ pageData.nickname }}</h1>
          </div>
          <div class="user-info-list">
            <div class="ud-cell">
              <user-outlined />
              <div class="ud-cell-content">{{ pageData.username }}</div>
            </div>
            <div class="ud-cell">
              <phone-outlined />
              <div class="ud-cell-content">{{ pageData.phone }}</div>
            </div>
            <div class="ud-cell">
              <mail-outlined />
              <div class="ud-cell-content">{{ pageData.email }}</div>
            </div>
          </div>
          <a-divider dashed />
        </a-card>
      </a-col>
      <a-col :lg="18" :md="16" :sm="24" :xs="24">
        <a-card :bordered="false" class="user-info-tabs">
          <a-tabs v-model:active-key="active" size="large">
            <a-tab-pane tab="基本信息" key="info">
              <a-form
                ref="form"
                :model="pageData"
                :rules="rules"
                :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
                :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
              >
                <a-form-item label="昵称:" name="nickname">
                  <a-input
                    v-model:value="pageData.nickname"
                    placeholder="请输入昵称"
                    allow-clear
                  />
                </a-form-item>
                <a-form-item label="性别:" name="sex">
                  <a-select
                    v-model:value="pageData.sex"
                    placeholder="请选择性别"
                    allow-clear
                  >
                    <a-select-option value="男">男</a-select-option>
                    <a-select-option value="女">女</a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="出生日期:" name="birthday">
                  <a-date-picker
                    class="ud-fluid"
                    value-format="YYYY-MM-DD"
                    placeholder="请选择出生日期"
                    v-model:value="pageData.birthday"
                  />
                </a-form-item>
                <a-form-item label="邮箱:" name="email">
                  <a-input
                    v-model:value="pageData.email"
                    placeholder="请输入邮箱"
                    allow-clear
                  />
                </a-form-item>
                <a-form-item label="个人简介:">
                  <a-textarea
                    v-model:value="pageData.introduction"
                    placeholder="请输入个人简介"
                    :rows="4"
                  />
                </a-form-item>
                <a-form-item label="地址:">
                  <a-cascader
                    v-model:value="pageData.fullCity"
                    :options="cityData.cityData"
                    placeholder="请选择省市区"
                    popup-class-name="ud-pop-wrap-higher"
                  />
                </a-form-item>
                <a-form-item label=" " :colon="false">
                  <a-input
                    v-model:value="pageData.address"
                    placeholder="请输入地址"
                    allow-clear
                  />
                </a-form-item>

                <a-form-item :wrapper-col="{ md: { offset: 6 } }">
                  <a-button type="primary" @click="save" :loading="loading">
                    {{ loading ? '保存中..' : '保存更改' }}
                  </a-button>
                </a-form-item>
              </a-form>
            </a-tab-pane>
            <a-tab-pane tab="账号绑定" key="account">
              <div class="user-account-list">
                <div class="ud-cell">
                  <wechat-outlined class="user-account-icon" />
                  <div class="ud-cell-content">
                    <div class="ud-cell-title">绑定微信</div>
                    <div class="ud-cell-desc">当前未绑定绑定微信账号</div>
                  </div>
                  <a>去绑定</a>
                </div>
                <a-divider />
              </div>
            </a-tab-pane>
          </a-tabs>
        </a-card>
      </a-col>
    </a-row>
    <!-- 头像裁剪弹窗 -->
    <ud-cropper-modal
      v-model:visible="showCropper"
      :src="pageData.avatar"
      @done="onCrop"
    />
  </div>
</template>

<script>
import {
  UploadOutlined,
  PhoneOutlined,
  MailOutlined,
  UserOutlined,
  WechatOutlined
} from '@ant-design/icons-vue'
import UdCropperModal from 'ud-admin-vue/packages/ud-cropper-modal'
import regions from '@/utils/regions'
export default {
  name: 'UserInfo',
  components: {
    UploadOutlined,
    PhoneOutlined,
    MailOutlined,
    UserOutlined,
    WechatOutlined,
    UdCropperModal
  },
  data() {
    return {
      // tab页选中
      active: 'info',
      // 表单数据
      pageData: {},
      // 省市区数据
      cityData: regions,
      // 表单验证规则
      rules: {
        nickname: [
          {
            required: true,
            message: '请输入昵称',
            type: 'string',
            trigger: 'blur'
          }
        ],
        sex: [
          {
            required: true,
            message: '请选择性别',
            type: 'string',
            trigger: 'blur'
          }
        ]
      },
      // 保存按钮loading
      loading: false,
      // 是否显示裁剪弹窗
      showCropper: false
    }
  },
  methods: {
    /* 保存更改 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          if (this.pageData.fullCity.length === 3) {
            this.pageData.areaCode = this.pageData.fullCity[2]
          } else {
            this.pageData.areaCode = ''
          }
          this.$http
            .post('/main/user', this.pageData)
            .then((res) => {
              if (res.data.code === 0) {
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
              setTimeout(() => {
                this.loading = false
              }, 1000)
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 头像裁剪完成回调 */
    onCrop(res) {
      this.pageData.avatar = res
      this.showCropper = false
      this.$store.dispatch(
        'user/setUser',
        Object.assign({}, this.$store.state.user.user, {
          avatar: res
        })
      )
    },
    initData() {
      const loginUser = this.$store.state.user.user
      this.pageData.userId = loginUser.userId
      this.pageData.usercode = loginUser.usercode
      this.pageData.username = loginUser.username
      this.pageData.nickname = loginUser.nickname
      this.pageData.sex = loginUser.sex + ''
      this.pageData.email = loginUser.email
      this.pageData.introduction = loginUser.introduction
      this.pageData.address = loginUser.address
      this.pageData.tell = loginUser.tell
      this.pageData.avatar = loginUser.avatar
      this.pageData.birthday = loginUser.birthday
      this.pageData.phone = loginUser.phone
      this.pageData.areaCode = loginUser.areaCode
      if (this.pageData.areaCode && this.pageData.areaCode.length === 6) {
        const fullCity = [
          this.pageData.areaCode.substring(0, 2).padEnd(6, '0'),
          this.pageData.areaCode.substring(0, 4).padEnd(6, '0'),
          this.pageData.areaCode.substring(0, 6).padEnd(6, '0')
        ]
        this.pageData.fullCity = fullCity
      }
    }
  },
  created() {
    this.initData()
  }
}
</script>

<style scoped>
/* 用户资料卡片 */
.user-info-avatar-group {
  margin: 16px 0;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.user-info-avatar-group .user-info-avatar-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 30px;
  display: none;
  z-index: 2;
}

.user-info-avatar-group:hover .user-info-avatar-icon {
  display: block;
}

.user-info-avatar-group:hover:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
}

.user-info-avatar-group + h1 {
  margin-bottom: 8px;
}

/* 用户信息列表 */
.user-info-list {
  margin: 52px 0 32px 0;
}

.user-info-list .ud-cell + .ud-cell {
  margin-top: 16px;
}

.user-info-list + .ant-divider {
  margin-bottom: 16px;
}

/* 用户标签 */
.user-info-tags {
  margin: 16px 0 4px 0;
}

.user-info-tags .ant-tag {
  margin: 0 12px 8px 0;
}

/* 右侧卡片 */
.user-info-tabs :deep(.ant-card-body) {
  padding: 0;
}

.user-info-tabs :deep(.ant-tabs-tab) {
  padding-left: 4px;
  padding-right: 4px;
  margin: 0 12px 0 28px !important;
}

.user-info-tabs .ant-form {
  max-width: 580px;
  margin-top: 20px;
  padding: 0 24px;
}

/* 用户账号绑定列表 */
.user-account-list {
  margin-bottom: 27px;
}

.user-account-list > .ud-cell {
  padding: 18px 34px;
}

.user-account-list .user-account-icon {
  color: #fff;
  padding: 8px;
  font-size: 26px;
  border-radius: 50%;
}

.user-account-list .user-account-icon.anticon-qq {
  background: #3492ed;
}

.user-account-list .user-account-icon.anticon-wechat {
  background: #4daf29;
}

.user-account-list .user-account-icon.anticon-alipay {
  background: #1476fe;
}
</style>
